.color-white {
  color: var(--color-white);
}
.color-gray {
  color: var(--color-gray);
}

.app-container {
  min-height: calc(100vh - 16rem - 56px);
  position: relative;
  top: -2rem;
}
.app-section {
  cursor: pointer;
  min-height: 12rem;
}
.app-category {
  cursor: pointer;
  min-height: 8rem;
}
.app-card {
  transition: box-shadow 150ms;
  box-shadow: 5px 5px 5px 0 var(--color-gray);
  &:hover {
    box-shadow: 6px 6px 0 6px var(--color-gray);
  }
}
.app-action-bar {
  background-color: var(--color-gray-2);
  min-height: 16rem;
}
.app-disclaimer {
  background-color: var(--color-gray-2);
}
.app-footer {
  height: 3rem;
  line-height: 3rem;
  background-color: var(--primary);
}

.app-admin-page {
  .card-header-tabs {
    margin: 0;
    border-bottom: 1px solid var(--color-gray-3);
  }
}

.app-notif{
    position: fixed;
    bottom: 20px;
    right: 20px;
    min-width: 300px
}

.app-link {
  &:hover {
    color: var(--color-white);
  }
}
