:root{
    --color-light-1: #fcfcfc;
    --color-dark-1 : #343a40;
    --color-gray-1 : #a3aab0;
    --color-gray-2 : #f3f3f3;
    --color-gray-3 : #dee2e6;
    --color-gray : #cccccc;
    --color-white: #ffffff;
    --color-black: #000000;
}

body {
    background-color: var(--color-light-1);
}